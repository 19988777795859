import userIcon from '../../../../assets/images/icon-user.svg';
import {
  IMsalContext,
  MsalAuthenticationTemplate,
  MsalConsumer,
  MsalProvider,
  useMsal,
  withMsal
} from '@azure/msal-react';
import cn from 'classnames';
import './styles/NavbarUserControl.scss';
import { MouseEventHandler, lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IOSVersion } from '../../../../helpers/getIOSVersion';
import { IDMConfig } from '../../../../types/dto/UserLogin';
import { getData } from '../../../../services/crud';
import { DSAContact } from '../../../../types/dto/DSAContact';
import { stringExtensions } from '../../../../helpers/stringExtensions';
import { env } from '../../../../environments/environment';
import { MenuMyZim } from '../../../../types/dto/MenuModel';
import WithRtl from '../../../../shared/WithRtl';
import { InteractionStatus } from '@azure/msal-browser';
import { myZIMLogdinGet } from '../../../../helpers/sharedValues';
import { appHelper } from '../../../../helpers/appHelper';
const RTL = lazy(() => import('./NavbarUserControlRtl'));

const NavbarUserControl = ({
  isOpen,
  onClick,
  isRoot = true,
  idmConfig = null,
  isMobile = false,
  myZim = null
}: {
  isOpen: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  isRoot?: boolean;
  idmConfig?: IDMConfig;
  isMobile?: boolean;
  myZim?: MenuMyZim;
}) => {
  const { instance, inProgress } = useMsal();

  const [dsaUserContact, setDsaUserContact] = useState<DSAContact>(null);
  const [userActive, setUserActive] = useState(false);

  const { t } = useTranslation();

  const translations = {
    userButtonLabel: t('accessibility.UserButtonLabel'),
    userImageAlt: t('accessibility.UserImageAlt'),
    loginUserHi: t('login.Hi')
  };

  useEffect(() => {
    if (!!instance?.getActiveAccount() && !!idmConfig && inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: idmConfig.knownAuthoritiesToGetToken
          .split(',')
          .map((a) => `https://${a}/tasks/read`),
        account: instance?.getActiveAccount()
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((res) => {
          if (!res.accessToken || res.accessToken === '') {
            setUserActive(true);
            return;
          }
          getData<DSAContact>(
            `${myZim?.api}${env.fixed.navbar.getMyZimContact}`,
            null,
            '',
            {
              Authorization: `Bearer ${res?.accessToken}`
            },
            true
          )
            .then((contactRes) => {
              if (!contactRes || isMobile) {
                setUserActive(true);
                if (contactRes && isMobile) {
                  appHelper.setDsaUserContact = contactRes;
                }
                return;
              }
              setDsaUserContact(contactRes);
              appHelper.setDsaUserContact = contactRes;
            })
            .catch((err) => {
              setUserActive(true);
            });
        })
        .catch((error) => {
          console.info('User data not available');
        });
    }
  }, [inProgress, instance, myZIMLogdinGet()]);

  return (
    <>
      <WithRtl lazyElement={RTL} />
      {instance && (
        <div
          className={cn('navbar-v2-user-control')}
          role="button"
          tabIndex={0}
          onClick={onClick}
          aria-expanded={isOpen}
          aria-label={translations.userButtonLabel}>
          <img
            className={cn('', {
              'navbar-v2-user-control-img-non-root': !isRoot
            })}
            src={userIcon}
            height="28"
            width="28"
            alt={translations.userImageAlt}
          />
          {dsaUserContact && (
            <span
              className={cn('navbar-v2-user-control-name', {
                'navbar-v2-user-control-name-non-root': !isRoot
              })}>{`${translations?.loginUserHi} ${stringExtensions.truncate(
              dsaUserContact?.contact?.firstName,
              12
            )}`}</span>
          )}
          {!dsaUserContact && userActive && <span className="user-active"></span>}
        </div>
      )}
    </>
  );
};

export default NavbarUserControl;
